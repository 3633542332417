import React, { memo } from 'react'
import Layout from 'components/Layout/Layout'
import Banner from 'components/Banner/Banner'
import styled from 'styled-components'
import SEO from 'components/Seo'
import { graphql } from 'gatsby'
import ServicesScrollSec from 'components/ServicesScrollSec/ServicesScrollSec'
import { md } from 'config/variables'
import { images } from 'config/images'

import FeatureResources from 'components/FeatureResources/FeatureResources'
import { useLazyLoad } from 'components/hooks/useLazyload'
import { useAnimationClass } from 'components/hooks/useAnimationClass'
import BuildApplication from 'components/BuildApplication/BuildApplication'
import ProcessFlow from 'components/ProcessFlow/ProcessFlow'
import GuaranteeSection from 'components/GuaranteeSection/GuaranteeSection'
import AWSPartnerCard from 'components/AWSPartnerCard/AWSPartnerCard'
import Relatedcaselist from 'components/Relatedcaselist/Relatedcaselist'
import RangeServiceListSec from 'components/RangeServiceListSec/RangeServiceListSec'
import { ApplicationFirewallProps } from 'components/Props/aws-application-firewall-and-security-service'

export const DigitalProductEngServicesPage = styled.div`
  .banner {
    .banner-content {
      max-width: 1110px;
      p {
        max-width: 1000px;
        margin: 0 auto;
        margin-bottom: 30px;
      }
    }
    .banner-img {
      display: block;
    }
  }
  .buildapp-section {
    margin-bottom: 0px;
  }
  .container {
    .service-right-wrapper {
      .service-right-section {
        .service-content-block {
          .blog-listing-wrapper {
            .blog-listing {
              .single-blog-card {
                figure {
                  min-height: 177px;
                  ${md(`        
                    min-height: 151px;
                  `)}
                }
              }
            }
          }
        }
      }
    }
  }
  .servicesScrollSec {
    .container {
      .heading-wrapper {
        max-width: 1000px;
      }
    }
  }
  .feature-resource-section {
    background: linear-gradient(to top, #eef2ff, #ffffff);
    .contact-info-card {
      display: none;
    }
  }
  .relatedcaselist-sec {
    h2 {
      text-align: center;
    }
  }
  .ppcbenefit-section {
    .container {
      .processflow-headig {
        max-width: 1000px;
      }
    }
  }
  .guarantee-section {
    .container {
      max-width: 1110px;
      h2.h3 {
        max-width: 840px;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
`

const SDSH = memo(props => {
  const { data } = props
  const { location } = props
  const { showContent } = useLazyLoad('.lazy-load-div')
  useAnimationClass({ showContent })

  let processFlowItem = [
    props.data.process1.childImageSharp.fluid,
    props.data.process2.childImageSharp.fluid,
    props.data.process3.childImageSharp.fluid,
    props.data.process4.childImageSharp.fluid,
  ]
  let AwsCardImg = [
    props.data.AWSCardOne.childImageSharp.fluid,
    props.data.AWSCardTwo.childImageSharp.fluid,
  ]
  return (
    <Layout
      mainClass="software-dev-services services-page"
      location={location}
      data={{
        img: images.contactMaitrik,
        img2x: images.contactMaitrik2x,
        name: 'Maitrik Kataria',
        description:
          'Helping businesses to accelerate growth by creating modern applications with AWS.',
      }}
    >
      <SEO
        title="AWS Application Firewall and Security Service"
        description="Simform offers custom application firewalls and security services to secure information and integrate data access controls into your systems."
        keywords={[`simform`]}
        links={[
          {
            href:
              'https://www.simform.com/services/aws-application-firewall-and-security/',
            rel: 'canonical',
          },
        ]}
        meta={[
          {
            property: 'og:url',
            content:
              'https://www.simform.com/services/aws-application-firewall-and-security/',
          },
          {
            property: 'og:image',
            content: data && data.bannerImage.childImageSharp.fluid.src,
          },
        ]}
      />
      <DigitalProductEngServicesPage>
        <Banner
          bannerData={ApplicationFirewallProps}
          showBannerImage={true}
          {...props}
        />
        <div className="lazy-load-div">
          <BuildApplication buildApplicationData={ApplicationFirewallProps} />
        </div>
        {showContent && (
          <>
            <ServicesScrollSec
              serviceScrollSecdata={ApplicationFirewallProps}
              {...props}
            />
            <ProcessFlow
              ProcessFlowData={ApplicationFirewallProps.workingBenefitsProps}
              items={processFlowItem}
            />
            <GuaranteeSection
              gauranteesecdata={ApplicationFirewallProps}
              {...props}
            />
            <AWSPartnerCard
              awardsRecognitionData={
                ApplicationFirewallProps.awardsRecognitionProps
              }
              ImageThumb={AwsCardImg}
            />
            <Relatedcaselist
              relatedcaselistdata={ApplicationFirewallProps}
              {...props}
            />
            <RangeServiceListSec
              rangeServiceListData={
                ApplicationFirewallProps.rangeServiceListDataProps
              }
            />
            <FeatureResources
              featureresourceData={ApplicationFirewallProps}
              {...props}
            />
          </>
        )}
      </DigitalProductEngServicesPage>
    </Layout>
  )
})

export default SDSH

export const query = graphql`
  query {
    bannerImage: file(
      relativePath: { regex: "/application-firewall-banner.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 1058) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    dvdReplication1: file(relativePath: { regex: "/sass-security-blog.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    dvdReplication2: file(relativePath: { regex: "/aws-hippa-blog.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    dvdReplication3: file(
      relativePath: { regex: "/web-application-blog.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    customSofBlog1: file(
      relativePath: { regex: "/blog-cost-optimization@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    customSofBlog2: file(
      relativePath: { regex: "/blog-serverless-architecture@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    customSofBlog3: file(
      relativePath: { regex: "/blog-mongodb-mysql@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    SoftTestingBlog1: file(
      relativePath: { regex: "/blog-test-coverage@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    SoftTestingBlog2: file(
      relativePath: { regex: "/blog-functional-testing@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    SoftTestingBlog3: file(
      relativePath: { regex: "/blog-mobile-app-testing@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    MobileAppBlog1: file(
      relativePath: { regex: "/blog-app-performance@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    MobileAppBlog2: file(relativePath: { regex: "/blog-app-debate@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    MobileAppBlog3: file(
      relativePath: { regex: "/blog-app-database@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    webAppBlog1: file(relativePath: { regex: "/blog-build-scalable@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    webAppBlog2: file(
      relativePath: { regex: "/blog-build-ecommerce@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    webAppBlog3: file(relativePath: { regex: "/blog-react-vue@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    DedicSoftBlog1: file(
      relativePath: { regex: "/blog-outsourcing-lessons@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    DedicSoftBlog2: file(
      relativePath: { regex: "/blog-ecommerce-app@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    DedicSoftBlog3: file(
      relativePath: { regex: "/blog-healthcare-app@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    softProdDevBlog1: file(
      relativePath: { regex: "/home-automation-using-iot-cover-image@2x.jpg/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    softProdDevBlog2: file(
      relativePath: { regex: "/industrial-iot-iota-part.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    softProdDevBlog3: file(relativePath: { regex: "/blog-gps-tracking.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    apiIntegBlog1: file(relativePath: { regex: "/blog-reactnative@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    apiIntegBlog2: file(relativePath: { regex: "/blog-roundup2019@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    apiIntegBlog3: file(relativePath: { regex: "/blog-mvc-mvp@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    appDevServiceBlog1: file(
      relativePath: { regex: "/blog-messaging-app@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    appDevServiceBlog2: file(
      relativePath: { regex: "/blog-like-airbnb@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    appDevServiceBlog3: file(relativePath: { regex: "/blog-mvc-mvp@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    hireDedicDevBlog1: file(
      relativePath: { regex: "/blog-outsourcing-lessons@2x/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    hireDedicDevBlog2: file(
      relativePath: { regex: "/blog-build-scalable@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    hireDedicDevBlog3: file(
      relativePath: { regex: "/blog-ecommerce-app@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    process1: file(relativePath: { regex: "/optimize-cost.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 320) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    process2: file(relativePath: { regex: "/customize-security.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 320) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    process3: file(
      relativePath: { regex: "/consulting-technical-support@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 320) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    process4: file(relativePath: { regex: "/aws-eminent-expertise.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 320) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    gauranteeTeam: file(
      relativePath: { regex: "/AWS-ce-rtificate-latest@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 1140) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    AWSCardOne: file(
      relativePath: { regex: "/aws-well-architected-logo@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 800) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    AWSCardTwo: file(
      relativePath: { regex: "/aws-immersion-days-logo@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 800) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    singlecase1: file(relativePath: { regex: "/abacode-case.jpg/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 220) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    singlecase2: file(relativePath: { regex: "/tagb-case.jpg/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 220) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
  }
`
