import React from 'react'
import { images } from 'config/images'
import { EXTERNAL_LINK } from 'config/utils'

export const ApplicationFirewallProps = {
  bannerProps: {
    bannerHeading: (
      <>
        Secure your web apps and APIs without disrupting{' '}
        <span className="heading-red">
          workflows with Application Firewall and Security Services
        </span>
      </>
    ),
    bannerContent:
      'We tailor firewall rules according to your business requirements and integrate data access controls into your systems for enhanced compliance.',
    BannerAlt: `Manage web app threats with Simform`,
  },
  buildApplicationProps: {
    buildApplicationHeading: (
      <>
        Manage web app threats with{' '}
        <span className="highlight-head">Simform</span>
      </>
    ),
    headDetails: [
      {
        Para: (
          <>
            We understand the need for secure networks and data protection in
            the age of hyper-digitization. So, to maintain operational
            resilience, our AWS WAF experts conduct comprehensive security
            assessments for your systems and design custom firewall policies to
            secure sensitive data.
            <br /> <br />
            Our team ensures continuous compliance through proactive monitoring.
            We leverage AWS Control Tower expertise to implement granular access
            controls while centralizing governance to minimize business
            disruptions.
          </>
        ),
      },
      {
        detailsList: [
          'Robust security against SQL injections, DDOS, XSS, and other attacks',
          'Custom AWS WAF rules and filters to block malicious traffic',
          'Easy to implement custom security policies across all systems',
          'Resilient systems with secure architecture design',
          'Expertise in data migration and integration',
          'Advanced monitoring, threat analysis, and vulnerability audits',
        ],
      },
    ],
  },
  clientLogoPrpos: {
    clientLogoHeading: (
      <>
        Trusted by <span className="color-through">900+ happy clients</span>{' '}
        including these Fortune companies
      </>
    ),
    clientLogos: [
      {
        clientLogoLink: 'https://www.redbull.com',
        clientLogosrc: images.redbull,
        clientLogosrcg: images.redbullg,
        clientLogoAlt: 'Redbull',
      },
      {
        clientLogoLink: 'https://www.pepsico.com/',
        clientLogosrc: images.pepsico,
        clientLogosrcg: images.pepsicog,
        clientLogoAlt: 'Pepsico',
      },
      {
        clientLogoLink: 'https://www.apa.org/',
        clientLogosrc: images.apag,
        clientLogosrcg: images.apa,
        clientLogoAlt: 'Americal Psychological Association',
      },
      {
        clientLogoLink: 'https://www.santander.co.uk/',
        clientLogosrc: images.santanderg,
        clientLogosrcg: images.santander,
        clientLogoAlt: 'Santander',
      },
      {
        clientLogoLink: 'https://www.bankofamerica.com/',
        clientLogosrc: images.boag,
        clientLogosrcg: images.boa,
        clientLogoAlt: 'Bank of America',
      },
      {
        clientLogoLink: 'https://www.cisco.com/',
        clientLogosrc: images.ciscog,
        clientLogosrcg: images.cisco,
        clientLogoAlt: 'Cisco',
      },
      {
        clientLogoLink: 'https://www.experian.com/',
        clientLogosrc: images.experiang,
        clientLogosrcg: images.experian,
        clientLogoAlt: 'Experian',
      },
      {
        clientLogoLink: 'https://www.fidelity.com/',
        clientLogosrc: images.fidelityg,
        clientLogosrcg: images.fidelity,
        clientLogoAlt: 'Fidelity',
      },
      {
        clientLogoLink: 'https://www.cameo.com/',
        clientLogosrc: images.cameog,
        clientLogosrcg: images.cameo,
        clientLogoAlt: 'Cameo',
      },
      {
        clientLogoLink: 'https://www.hsbc.com/',
        clientLogosrcg: images.hsbc,
        clientLogosrc: images.hsbcg,
        clientLogoAlt: 'HSBC',
      },
      {
        clientLogoLink: 'https://www.jpmorganchase.com/',
        clientLogosrc: images.jpmg,
        clientLogosrcg: images.jpm,
        clientLogoAlt: 'JP Morgan',
      },
      {
        clientLogoLink: 'https://www.larvol.com/',
        clientLogosrc: images.larvolg,
        clientLogosrcg: images.larvol,
        clientLogoAlt: 'Larvol',
      },
      {
        clientLogoLink: 'https://www.natwestgroup.com',
        clientLogosrc: images.netwestg,
        clientLogosrcg: images.netwest,
        clientLogoAlt: 'NetWest',
      },
      {
        clientLogoLink: 'https://solvpath.com/',
        clientLogosrc: images.solvepathg,
        clientLogosrcg: images.solvepath,
        clientLogoAlt: 'Solve Path',
      },
      {
        clientLogoLink: 'https://nextbigideaclub.com/',
        clientLogosrc: images.nbicg,
        clientLogosrcg: images.nbic,
        clientLogoAlt: 'NBIC',
      },
      {
        clientLogoLink: 'https://www.news.co.uk/',
        clientLogosrc: images.newsukg,
        clientLogosrcg: images.newsuk,
        clientLogoAlt: 'News UK',
      },
    ],
    clientCount: '900+',
    clienttitle: 'Happy Clients',
  },
  serviceScrollSecProps: {
    servicesHeading: (
      <>
        Our AWS application firewall and security{' '}
        <span className="highlight-head">services</span>
      </>
    ),
    servicesPara:
      'We leverage AWS best practices to ensure secure web traffic and data access control. Our team monitors and assesses data breaches and provides an incident response system.',
    sidebarHeading: 'Services',
    serviceListProps: [
      {
        heading: 'AWS WAF',
        className: 'custom-software',
        mainPara: (
          <>
            Our AWS WAF experts swiftly implement customized security policies,
            audit your applications, and fine-tune firewall filters to block
            malicious web traffic. We also enable real-time traffic visibility
            for security incidents by integrating Amazon CloudWatch.
          </>
        ),
        listItem: [
          {
            title: 'Real-time threat monitoring',
            para:
              'Our experts monitor network activities, analyze attachment patterns, and generate reports for proactive threat prevention. This allows businesses to ensure there are no incidents due to cyber threats.',
          },
          {
            title: 'Integration with SIEM Solutions',
            para:
              'We improve your security infrastructure by integrating AWS WAF with SIEM solutions. This provides centralized security data analysis for threat detection and response.',
          },
          {
            title: 'Incident response and management',
            para:
              'Our experts use advanced techniques such as anomaly detection and rate limiting to identify and isolate malicious traffic. This allows legitimate requests to pass through without disruptions.',
          },
        ],
        serviceButtonText: 'Read More',
        ServiceLink: '/services/aws-web-application-firewall/',
        type: EXTERNAL_LINK,
      },
      {
        heading: 'AWS Control Tower',
        className: 'software-testing',
        mainPara: `Simform simplifies data governance, compliance, and configuration management across environments. We leverage modular architecture design to offer a more granular data access control. Our team enables centralized governance for multiple AWs accounts across different environments.`,
        listItem: [
          {
            title: 'Comprehensive data visibility',
            para:
              'We provide a centralized dashboard that is tailored to your needs, offering real-time data visibility and monitoring of policy violations. This simplifies data security, compliance, and resource allocation management.',
          },
          {
            title: 'Automated configurations',
            para:
              'Our team of AWS experts can assist you with configuring account creation, enrollment, and policy application, resulting in improved management of AWS accounts across environments.',
          },
          {
            title: 'Enhanced data control',
            para:
              'Our AWS experts design landing zones and guardrails for secure data storage and processing. This ensures better data control for your infrastructure and higher security.',
          },
        ],
        serviceButtonText: 'Read More',
        ServiceLink: '/services/aws-control-tower-services/',
        type: EXTERNAL_LINK,
      },
    ],
  },
  workingBenefitsProps: {
    workingBenefitsHeading: (
      <>
        Why choose <span className="highlight-head"> Simform?</span>
      </>
    ),
    processPara: `Maintaining web app security requires more than encrypting data or implementing a firewall. Simform provides extensive security assessments and adjustments according to changing market demands.`,
    workingBenefitsProps: [
      {
        workingBenefitsClass: 'blue-gradient',
        workingBenefitsTitle: 'Optimized cost and performance',
        workingBenefitsContent: (
          <>
            Simform helps optimize your WAF costs so you achieve maximum ROI
            from application security protections.
            <br />
            <br />
            Our team reviews your WAF spending periodically and prioritizes
            security posture to ensure you avoid overspending on unused or
            unnecessary protections.
          </>
        ),
        benefitImgAlt: 'Optimized cost and performance',
        workingBenefitsPatern: images.processFlowLightBlue,
      },
      {
        workingBenefitsClass: 'blue-gradient',
        workingBenefitsTitle: 'Customized security',
        workingBenefitsContent: (
          <>
            Simform ensures all the security solutions and deployment strategies
            align with your business goals.
            <br />
            <br />
            This includes alignment of security policies, workflow automation,
            data compliance, and configuration management.
          </>
        ),
        benefitImgAlt: 'Customized security',
        workingBenefitsPatern: images.processFlowLightBlueDown,
      },
      {
        workingBenefitsClass: 'blue-gradient',
        workingBenefitsTitle: 'Comprehensive support',
        workingBenefitsContent: (
          <>
            Simform stands with you throughout your app’s lifecycle, covering
            design, development, and deployment.
            <br />
            <br />
            Our dedicated experts go beyond standard support, monitoring
            applications even after the launch for optimal performance and ROI.
          </>
        ),
        benefitImgAlt: 'Comprehensive support',
        workingBenefitsPatern: images.processFlowLightBlue,
      },
      {
        workingBenefitsClass: 'blue-gradient',
        workingBenefitsTitle: 'Compliance expertise',
        workingBenefitsContent: (
          <>
            Simform maintains auditable environments that readily meet data
            regulation standards like HIPAA, PCI DSS, and GDPR.
            <br />
            <br />
            Our team analyzes your current web app firewall architecture and
            policies to fine-tune security across the organization, ensuring
            higher compliance.
          </>
        ),
        benefitImgAlt: 'Compliance expertise',
      },
    ],
  },
  gauranteeSecProps: {
    heading: (
      <>
        Work with an official{' '}
        <span className="highlight-head">AWS Premier Consulting Partner</span>
      </>
    ),
    para:
      'Simform, with its cloud partners, brings you the superpower of cloud-native applications. Our strategic alliances empower organizations like yours to thrive in the cloud.',
    img: images.gauranteeTeam,
    img2x: images.gauranteeTeam2x,
    gauranteeAlt: 'Extended tech team',
  },
  awardsRecognitionProps: {
    heading: 'Learn more about our AWS partner programs',
    AwsCardData: [
      {
        cardLink: '/aws-well-architected-review/',
      },
      {
        cardLink: '/aws-immersion-days/',
      },
    ],
  },
  relatedcaselistProps: {
    heading: (
      <>
        <span className="highlight-head">Case Studies</span>
      </>
    ),
    caselistProps: [
      {
        title: 'Pioneering cybersecurity for high-risk applications',
        para:
          'A next-gen managed cybersecurity & compliance provider that unifies technology and services for tailored, risk-aware cybersecurity solutions.',
        className: 'light-peach',
        link: '/abacode-control-tower/',
      },
      {
        title: 'A scalable parking and valet management system',
        para:
          'Parking and valet management system for multiple users and vendors, with a focus on multi-tenancy, security, database, and infrastructure as a code.',
        className: 'pale-blue',
        link: '/tagb-waf/',
      },
    ],
  },
  rangeServiceListDataProps: {
    RangeServiceListHeading: 'About Simform’s AWS Practices',
    detailsList: [
      <>
        <strong>
          Simform is an official premier consulting partner of AWS
        </strong>{' '}
        and hence, follows an approved set of practices that define the best
        work in configuring AWS services.
      </>,
      <>
        <strong>Devoted subject matter expert</strong> to walk you through your
        project and assist teams during the problems.
      </>,
      <>
        <strong>A well-structured team</strong> of technical officers whose
        significant tenure belongs to cloud technologies and AWS services.
      </>,
      <>
        <strong>A 24/7 constant communication</strong> to help you get through
        the AWS assistance, zero instances of delayed service.
      </>,
      <>
        <strong>Simform’s standard auditing and quality assurance</strong> over
        every module of code we deliver through our consulting services.
      </>,
    ],
  },
  featureResourceProps: {
    heading: 'AWS insights from our experts',
    featureContents: [
      {
        blogLink:
          'https://www.simform.com/blog/best-practices-to-build-data-pipelines/',
        featurecardTitle:
          'SaaS Security: Guide to Principles, Challenges, and their Best Practices',
      },
      {
        blogLink:
          'https://www.simform.com/blog/data-warehouse-vs-data-lake-vs-data-lakehouse/',
        featurecardTitle:
          'AWS HIPAA Compliance: Ensuring Data Security in Healthcare',
      },
      {
        blogLink: 'https://www.simform.com/blog/data-engineering-tools/',
        featurecardTitle:
          'Web Application Security: Best Practices to stop Threats',
      },
    ],
  },
}
